<template>
  <!-- apply-images -->
  <swiper
      class="swiper-wrapper facility-swipe-container facility-images"
      :loop="false"
      :pagination="{
          el: '.swiper-pagination',
          clickable: true,
      }"
      :slides-offset-before="16"
  >
    <swiper-slide v-for="(item, idx) in imageList" :key="idx" >
      <div class="facility-image swiper-slide"><img :src="item.src" :alt="item.title"/></div>
    </swiper-slide>

    <!-- If we need pagination -->
    <div class="facility-pagination swiper-pagination"></div>

    <!-- If we need navigation buttons -->
<!--
    <button type="button" class="swiper-button-prev"><i class="icon-prev"></i></button>
    <button type="button" class="swiper-button-next "><i class="icon-next"></i></button>
    -->
  </swiper>
  <!-- //apply-images -->
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';

export default {
  name: 'ImageSwiperMobileComponent',
  components: {
    Swiper,
    SwiperSlide
  },
  props:{
    imageList: {
      type: Array,
      default: null
    }
  },
  setup() {

    return {

    }
  }
};
</script>